.fullsize {
    width: 100%;
}

.flex {
    display: flex;
}

.flex.center {
    justify-content: center;
}

.material-icons.large {
    font-size: 60px;
}

.mdc-top-app-bar--fixed-adjust {
    padding-top: 48px;
}

.muted {
    color: #757575;
}

.left {
    float: left;
}

.right {
    float: right;
}

.material-icons.left {
    margin-right: 5px;
    line-height: 1.2;
}

.material-icons.right {
    margin-left: 5px;
    line-height: 1.2;
}

.margin-after {
    margin-bottom: 10px !important;
}

.padding {
    padding: 24px;
}