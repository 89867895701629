//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

$mdc-dialog-scrim-color: on-surface !default;
$mdc-dialog-title-ink-color: on-surface !default;
$mdc-dialog-content-ink-color: on-surface !default;
$mdc-dialog-scroll-divider-color: on-surface !default;

$mdc-dialog-scrim-opacity: .32 !default;
$mdc-dialog-title-ink-opacity: .87 !default;
$mdc-dialog-content-ink-opacity: .6 !default;
$mdc-dialog-scroll-divider-opacity: .12 !default;

$mdc-dialog-min-width: 280px !default;
$mdc-dialog-max-width: 560px !default;
$mdc-dialog-margin: 16px !default;
$mdc-dialog-title-bottom-padding: 9px !default;

$mdc-dialog-z-index: 7 !default;
