$mdc-theme-primary: #f44336;
$mdc-theme-secondary: #ffb300;
$mdc-theme-background: #fff;

// eslint-disable-next-line
//@import "~material-components-web/";
@import "../material-components-web/packages/mdc-top-app-bar/mdc-top-app-bar";
@import "../material-components-web/packages/mdc-drawer/mdc-drawer";
@import "../material-components-web/packages/mdc-list/mdc-list";
@import '../material-components-web/packages/mdc-textfield/mdc-text-field';
@import '../material-components-web/packages/mdc-layout-grid/mdc-layout-grid';
@import "../material-components-web/packages/mdc-button/mdc-button";
@import "../material-components-web/packages/mdc-typography/mdc-typography";
@import "../material-components-web/packages/mdc-dialog/mdc-dialog";
@import "../material-components-web/packages/mdc-linear-progress/mdc-linear-progress";
@import "../material-components-web/packages/mdc-snackbar/mdc-snackbar";
